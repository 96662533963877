body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

button {
  text-transform: none;
}
/* Add the following styles */
.theme-buttons-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000; /* Ensure buttons are above other content */
}
